// src/pages/Relatorios/RelatoriosPage.tsx

import AssessmentIcon from '@mui/icons-material/Assessment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXMainLayout from '../../../components/main-layout/QCXMainLayout';
import CardsModalGrid, { CardsModalGridItem } from '../../common/components/cardsGrid/cardsModalGrid';
import theme from '../../common/themes/theme';
import ContasPagarPorDataVencimentoPage from './contasPagarPorDataVencimento/contasPagarPorDataVencimentoPage';
import ContasReceberPage from './contasReceber/ContasReceberPage';
import ContasRecebidasPage from './contasRecebidas/contasRecebidasPage';
import DespesasPagasPorFornecedorPage from './despesasPagasPorFornecedor/despesasPagasPorFornecedorPage';
import RelatorioContaCorrentePage from './relatorioContaCorrente/relatorioContaCorrentePage';
import RelatorioDevolucaoSaldoPage from './relatorioDevolucaoSaldo/relatorioDevolucaoSaldoPage';
import RelatorioLancamentosPage from './relatorioLancamentos/relatorioLancamentosPage';
import RelatorioLivroContasPage from './relatorioLivrocontas/relatorioLivrocontasPage';
import RelatorioMovimentacaoPage from './relatorioMovimentacao/relatorioMovimentacaoPage';
import RelatorioNfEmitidasPage from './relatorioNfEmitidas/relatorioNfEmitidasPage';
import RelatorioProcessosFaturadosPage from './relatorioProcessosFaturados/relatorioProcessosFaturadosPage';
import RelatorioProcessosSaldoPage from './relatorioProcessosSaldo/relatorioProcessosSaldoPage';
import RelatorioFaturadosSaldoPage from './relatorioFaturadosSaldo/relatorioFaturadosSaldo';
import RelatorioDespesasPage from './relatorioDespesas/relatorioDespesasPage';
import RelatorioReceitasPage from './relatorioReceitas/relatorioReceitasPage';
import ContaCorrenteClientePage from './contaCorrenteCliente/contaCorrenteClientePage';
import RelatorioIcmsPago from './relatorioICMS/relatorioIcmsPage';

const financeiroModalGridItems: CardsModalGridItem[] = [
  {
    title: 'Contas a Pagar por Data de Vencimento',
    icon: InsertDriveFileIcon,
    modalName: 'CONTAS_PAGAR_POR_DATA_VENCIMENTO',
    modalComponent: ContasPagarPorDataVencimentoPage,
  },
  {
    title: 'Despesas Pagas por Fornecedor Cliente e Data de Pagamento',
    icon: InsertDriveFileIcon,
    modalName: 'DESPESAS_PAGAS_POR_FORNECEDOR',
    modalComponent: DespesasPagasPorFornecedorPage,
  },
  {
    title: 'Contas a Receber',
    icon: InsertDriveFileIcon,
    modalName: 'CONTAS_RECEBER',
    modalComponent: ContasReceberPage,
  },
  {
    title: 'Contas Recebidas',
    icon: InsertDriveFileIcon,
    modalName: 'CONTAS_RECEBIDAS',
    modalComponent: ContasRecebidasPage,
  },
  {
    title: 'NF Emitidas',
    icon: InsertDriveFileIcon,
    modalName: 'NF_EMITIDAS',
    modalComponent: RelatorioNfEmitidasPage,
    requiredRoles: ['relatorio-nfemitidas-visualizar'],
  },
  {
    title: 'Livro caixa',
    icon: InsertDriveFileIcon,
    modalName: 'LIVRO_CAIXA',
    modalComponent: RelatorioLivroContasPage,
    requiredRoles: ['relatorio-livrocontas-visualizar'],
  },
  {
    title: 'Movimentação Financeira',
    icon: InsertDriveFileIcon,
    modalName: 'MOVIMENTACAO',
    modalComponent: RelatorioMovimentacaoPage,
    requiredRoles: ['movimentacao-visualizar'],
  },
  {
    title: 'Devolução de Saldo Por Cliente',
    icon: InsertDriveFileIcon,
    modalName: 'DEVOLUCAO_SALDO_POR_CLIENTE',
    modalComponent: RelatorioDevolucaoSaldoPage,
    requiredRoles: ['relatorio-devolucao-saldo-visualizar'],
  },
  {
    title: 'Processos Faturados',
    icon: InsertDriveFileIcon,
    modalName: 'FATURADOS',
    modalComponent: RelatorioProcessosFaturadosPage,
    requiredRoles: ['relatorio-processos-faturados-visualizar'],
  },
  {
    title: 'Processos Faturados e Com Saldo',
    icon: InsertDriveFileIcon,
    modalName: 'FATURADOS_SALDO',
    modalComponent: RelatorioFaturadosSaldoPage,
    requiredRoles: ['relatorio-faturados-saldo-visualizar'],
  },
  {
    title: 'Processos em Andamento com Saldo',
    icon: InsertDriveFileIcon,
    modalName: 'ANDAMENTO_COM_SALDO',
    modalComponent: RelatorioProcessosSaldoPage,
    requiredRoles: ['relatorio-processos-andamento-visualizar'],
  },
  {
    title: 'Conta Corrente',
    icon: InsertDriveFileIcon,
    modalName: 'CONTA_CORRENTE',
    modalComponent: RelatorioContaCorrentePage,
    requiredRoles: ['relatorio-conta-corrente-visualizar'],
  },
  {
    title: 'Contas a Receber - Lançamentos Baixados',
    icon: InsertDriveFileIcon,
    modalName: 'CONTAS_RECEBER_LANCAMENTOS',
    modalComponent: RelatorioLancamentosPage,
    requiredRoles: ['relatorio-lancamentos-visualizar'],
  },
  {
    title: 'Conta Corrente cliente',
    icon: InsertDriveFileIcon,
    modalName: 'CONTA_CORRENTE_CLIENTE',
    modalComponent: ContaCorrenteClientePage,
    requiredRoles: ['relatorio-conta-corrente-visualizar'],
  },
  {
    title: 'Despesas',
    icon: InsertDriveFileIcon,
    modalName: 'DESPESAS',
    modalComponent: RelatorioDespesasPage,
    requiredRoles: ['relatorio-despesas-visualizar'],
  },
  {
    title: 'Receitas',
    icon: InsertDriveFileIcon,
    modalName: 'RECEITAS',
    modalComponent: RelatorioReceitasPage,
    requiredRoles: ['relatorio-receitas-visualizar'],
  },
  {
    title: 'Controle de ICMS Pago',
    icon: InsertDriveFileIcon,
    modalName: 'ICMS_PAGO',
    modalComponent: RelatorioIcmsPago,
    requiredRoles: ['relatorio-despesas-visualizar'],
  },
  {
    title: 'Conta Corrente Cliente',
    icon: InsertDriveFileIcon,
    modalName: 'CONTA_CORRENTE_CLIENTE',
    modalComponent: ContaCorrenteClientePage,
    requiredRoles: ['relatorio-conta-corrente-visualizar'],
  },
];

const FinanceiroRelatoriosPage: React.FC = ({ authInfo = {} }: any) => {
  const { t } = useTranslation();

  const [reportOpenName, setReportOpenName] = useState('');
  const closeOpenReport = () => setReportOpenName('');
  const openReportByName = (name: string) => () => setReportOpenName(name);

  return (
    <ThemeProvider theme={theme}>
      <QCXMainLayout
        key="main-layout"
        pageTitle={'Relatórios Financeiros'}
        pageIcon={<AssessmentIcon color={'secondary'} />}
        breadcrumbs={[
          { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
          { link: { to: '/financeiro', name: 'Financeiro' } },
          { text: { name: 'Relatórios Financeiros' } },
        ]}
        authInfo={authInfo}
      >
        <CardsModalGrid
          items={financeiroModalGridItems}
          modalOpenName={reportOpenName}
          openModalByName={openReportByName}
          closeModal={closeOpenReport}
        />
      </QCXMainLayout>
    </ThemeProvider>
  );
};

export default FinanceiroRelatoriosPage;
